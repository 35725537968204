import ReactDOM from "react-dom"
import React, { Suspense, useEffect, useRef, useMemo, createContext } from "react"
import { Canvas, Dom, useLoader, useFrame, useThree } from "react-three-fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { TextureLoader, LinearFilter, WebGLRenderTarget, Object3D } from "three"
import lerp from "lerp"
import BackfaceMaterial from "../src/diamonds/BackfaceMaterial"
import RefractionMaterial from "../src/diamonds/RefractionMaterial"
import { Text, MultilineText } from "./components/Text"
import Diamonds from "./diamonds/Diamonds"
import Plane from "./components/Plane"
import { Block, useBlock } from "./blocks"
import state from "./store"
import "./styles.css"
import { SingleDiamond } from "./SingleDiamond"

const offsetContext = createContext(0)

function Startup() {
  const ref = useRef()
  useFrame(() => (ref.current.material.opacity = lerp(ref.current.material.opacity, 0, 0.025)))
  return <Plane ref={ref} color="#0e0e0f" position={[0, 0, 200]} scale={[100, 100, 1]} />
}

function App() {
  const scrollArea = useRef()
  const onScroll = (e) => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])
  return (
    <>
      <div className="title">
        <h1>Domus</h1>
        <p>Changing the world in a positive, lasting way.</p>
      </div>
      <Canvas className="canvas" concurrent pixelRatio={1} orthographic camera={{ zoom: 100, position: [0, 0, 500] }}>
        <Suspense fallback={<Dom center className="loading" children="Loading..." />}>
          <SingleDiamond />
        </Suspense>
      </Canvas>
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
        {new Array(state.sections).fill().map((_, index) => (
          <div key={index} id={"0" + index} style={{ height: `${(state.pages / state.sections) * 100}vh` }} />
        ))}
      </div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))
